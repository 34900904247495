import './App.css';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import config from './amplifyconfiguration.json';
Amplify.configure(config);

export function App({ signOut, user }) {
  return ( <>
    <h1>Hello {user?.username}</h1>
    <button onClick={signOut}>Sign out</button>
  </>
  );
}

export default withAuthenticator(App);
